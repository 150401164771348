import React, { useEffect, useState } from "react";
import Profile from "./components/Profile";
import { user } from "../../DB";
import SuggestedFreinds from "./components/SuggestedFreinds";
import FixturesSub from "./components/Fixtures";
import TrendingNews from "./components/TrendForYou/TrendingNews";
import TrendForYou from "./components/TrendForYou/TrendForYou";
import PostBlog from "./components/Post/PostBlog";
import Postcards from "./components/Post/Postcards";
import { useDispatch, useSelector } from "react-redux";
import { gePost } from "../../store/Community";
import PostLoader from "../../Loaders/PostLoader";
import { useLocation } from "react-router-dom";
import { qParams } from "../../utils/getQueryParams";
/*eslint-disable*/

function Community() {
    const posts = useSelector((state) => state.community?.posts);
    const dispatch = useDispatch();
    const location = useLocation();
    const [history, setHistory] = useState(window.location);
    let type = qParams("type", location.search);
    const auth = useSelector((state) => state.auth?.user);

// console.debug(typeof(posts))

    useEffect(() => {
        setHistory(window.location);
    }, [location]);

    useEffect(() => {
        // Dispatch gePost initially
        dispatch(gePost());

        // Set interval to dispatch gePost every 5 seconds
        const intervalId = setInterval(() => {
            dispatch(gePost());
        }, 5000); // 5000 milliseconds = 5 seconds

        // Cleanup function to clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [dispatch]); // Dependency array with dispatch as dependency

    return (
        <div className=" community">
            <div className="container-fluid ">
                <div className="row m-0">
                    <div className="col-md-3  sidebarleft p-0 ml-auto centerPage d-none d-md-block">
                        <Profile user={auth} />
                        <TrendingNews user={auth} />
                        <SuggestedFreinds auth={auth} length={3} />
                    </div>
                    <div className="col-md-6 p-0 mx-auto centerPage">
                        {type != "trend" ? (
                            <div className="mx-md-4" history>
                                <PostBlog />
                                {posts?.length ? (
                                    posts?.map((item, key) => (
                                        <Postcards item={item} key={key} />
                                    ))
                                ) : (
                                    <PostLoader />
                                )}
                            </div>
                        ) : (
                            <div className="mx-md-4">
                                <TrendForYou user={auth} />
                            </div>
                        )}
                    </div>
                    <div className="col-md-3  p-0 mr-auto centerPage d-none d-md-block">
                        <TrendForYou user={auth} length={8} />
                        <FixturesSub user={auth} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Community;
