import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { navlinks } from "../../DB";
import { signoutReq } from "../../utils/request";
import Search from "../../components/Search";
import { Sidebar } from "primereact/sidebar";
import { Badge } from "primereact/badge";

/*eslint-disable*/

function Header() {
    const route = useLocation().pathname;
    const loaderInfo = (url) => {
        if (url === "/sport") {
            window.location.href = url;
        }
    };
    const token = useSelector((state) => state.general?.token);
    const notificationCount = useSelector(
        (state) => state.notification?.notificationCount
    );
    const chatCount = useSelector(
        (state) => state.notification?.chatCount
    );
    const auth = useSelector((state) => state.auth?.user);
    const navigate = useNavigate();
    const navigateM = (url) => {
        setVisibleLeft(false);
        navigate(url);
    };
    const [visibleLeft, setVisibleLeft] = useState(false);
    const [searchPanel, setSearchPanel] = useState(false);
    return (
        <div
            className={`header mb-header ${
                (route === "/chats" || route === "/chat") && "d-none d-md-block"
            }`}
        >
            <nav className="navbar navbar-expand-md navbar-light  bg-white fixed-top">
                <div className="container-fluid ">
                    <Link
                        className="navbar-brand pl-0 ml-0 d-none d-md-flex align-items-center"
                        to="/"
                    >
                        <img
                            src="/images/nav/logo.png"
                            className="nav-logo"
                            alt=""
                        />

                        <span className="NewLogo">Soccernity</span>
                    </Link>
                    <Link
                        className="navbar-brand navbar-brand-2 d-block d-md-none ml-0"
                        to="/"
                    >
                        <img src="/logo.png" alt="" className="logoImg" />
                        {/* <span className="logoName">Soccernity</span> */}
                    </Link>
                    <div className="d-none d-md-flex">
                        <Search /> {/* desktop Version */}
                    </div>

                    {searchPanel && (
                        <div
                            className="d-inline d-md-none"
                            onMouseOut={() => setSearchPanel(false)}
                        >
                            <Search /> {/* desktop Version */}
                        </div>
                    )}
                    <div>
                        {!searchPanel && (
                            <React.Fragment>
                                <button
                                    type="button"
                                    className="navbar-toggler d-lg-none border-0 "
                                    onClick={() => setSearchPanel(true)}
                                >
                                    <span
                                        className="pi pi-search  text-black"
                                        style={{ fontSize: "1.8rem" }}
                                    ></span>
                                </button>
                                <button
                                    className="navbar-toggler d-lg-none border-0 "
                                    type="button"
                                    onClick={() => navigate('/profile?type=notification')}
                                >
                                    <span
                                        className="pi pi-bell  text-black"
                                        style={{ fontSize: "1.8rem" }}
                                    ></span>
                                    {notificationCount ? (
                                        <Badge
                                            value={
                                                notificationCount >= 9
                                                    ? `9+`
                                                    : notificationCount
                                            }
                                            severity="danger"
                                            className="mobileNotification"
                                        ></Badge>
                                    ) : (
                                        ""
                                    )}
                                </button>
                            </React.Fragment>
                        )}
                        <button
                            className="navbar-toggler d-lg-none border-0 "
                            type="button"
                            onClick={() => {
                                setVisibleLeft(true);
                                setSearchPanel(false);
                            }}
                        >
                            <span
                                className="pi pi-bars text-black"
                                style={{ fontSize: "1.8rem" }}
                            ></span>
                        </button>
                    </div>
                    <div
                        className="collapse navbar-collapse bg-white"
                        id="collapsibleNavId"
                    >
                        <ul className="navbar-nav mr-auto mt-2 mt-lg-0 ">
                            {navlinks?.map((item, key) => (
                                <React.Fragment key={key}>
                                    {item?.url !== "/" && (
                                        <li
                                            key={key}
                                            className={`nav-item  ${
                                                item?.url === route &&
                                                "hoverBox"
                                            } `}
                                        >
                                            <Link
                                                className="nav-link d-md-inline d-none"
                                                to={item?.url}
                                                title={item?.name}
                                                onClick={() =>
                                                    loaderInfo(item?.url)
                                                }
                                            >
                                                {item?.url === route && (
                                                    <img
                                                        src={item?.hover_icon}
                                                        alt=""
                                                    />
                                                )}
                                                {item?.url !== route && (
                                                    <img
                                                        src={item?.icon}
                                                        alt=""
                                                    />
                                                )}
                                            </Link>
                                            {/* <Link
                                        className="nav-link d-md-none d-flex"
                                        to={item?.url}
                                        title={item?.name}
                                        onClick={() => loaderInfo(item?.url)}
                                    >
                                        {item?.name}
                                    </Link> */}
                                        </li>
                                    )}
                                </React.Fragment>
                            ))}
                            <li className={`nav-item`}>
                                <Link
                                    to={`/profile?user=${auth?.email}`}
                                    className="nav-link d-md-none d-flex"
                                >
                                    Profile
                                </Link>
                            </li>
                            <li className={`nav-item`}>
                                <Link
                                    to={`/chats`}
                                    className="nav-link d-md-none d-flex"
                                >
                                    Chats
                                </Link>
                            </li>
                            {token && (
                                <li
                                    className={`nav-item`}
                                    onClick={() => signoutReq()}
                                >
                                    <span className="nav-link d-md-none d-flex ">
                                        Logout
                                    </span>
                                </li>
                            )}
                        </ul>
                        <form className="form-inline second-nav my-2 my-lg-0 d-none d-md-inline ">
                            {token ? (
                                <span>
                                    {/* <img src="/images/nav/notify.png" alt="" className="topImg-header" /> */}
                                    <img
                                        src="/images/newNAV/chats.svg"
                                        alt=""
                                        onClick={() => navigate("/chats")}
                                        className="topImg-header d-none d-md-inline"
                                        title="Chats"
                                    />
                                     {chatCount ? (
                                        <Badge
                                            severity="danger"
                                            className="smallNotificationBadge"
                                        ></Badge>
                                    ) : (
                                        ""
                                    )}
                                    <img
                                        src={
                                            auth?.profile?.profile_photo ??
                                            "/images/avater.png"
                                        }
                                        alt=""
                                        className="topImg-header profile-mini-avatar border shadow  "
                                        id="triggerId"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    />
                                    {notificationCount ? (
                                        <Badge
                                            severity="danger"
                                            className="smallNotificationBadge"
                                        ></Badge>
                                    ) : (
                                        ""
                                    )}
                                    <div className="dropdown  ">
                                        <div
                                            className="dropdown-menu p-0 shadow"
                                            aria-labelledby="triggerId"
                                        >
                                            <Link
                                                className="dropdown-item  border-bottom"
                                                to={`/profile?user=${auth?.email}`}
                                            >
                                                Profile
                                            </Link>
                                            <Link
                                                className="dropdown-item  border-bottom"
                                                to={`/setting`}
                                            >
                                                Settings
                                            </Link>
                                            <Link
                                                className="dropdown-item  border-bottom"
                                                to={`/profile?type=notification`}
                                            >
                                                Notifications
                                                {notificationCount ? (
                                                    <Badge
                                                        value={
                                                            notificationCount >=
                                                            9
                                                                ? `9+`
                                                                : notificationCount
                                                        }
                                                        severity="danger"
                                                    ></Badge>
                                                ) : (
                                                    ""
                                                )}
                                            </Link>
                                            <Link
                                                className="dropdown-item  border-bottom"
                                                to={`/contact`}
                                            >
                                                Contact Us
                                            </Link>
                                            <button
                                                className="dropdown-item "
                                                onClick={() => signoutReq()}
                                            >
                                                Logout
                                            </button>
                                        </div>
                                    </div>
                                </span>
                            ) : (
                                <button
                                    className="isloggesIN btn"
                                    onClick={() => navigate("/auth/signin")}
                                >
                                    Login
                                </button>
                            )}

                            {/* {isInbox && <Inbox />} */}
                        </form>
                    </div>
                </div>
            </nav>

            <Sidebar
                visible={visibleLeft}
                position="left"
                onHide={() => setVisibleLeft(false)}
            >
                <div className="mobileSideBar  bg-white">
                    <Link className="navbar-brand ml-0" to="/">
                        <img src="/logo.png" alt="" className="logoImg" />
                        <span className="logoName">Soccernity</span>
                    </Link>
                    <hr />
                    <ul className="navbar-nav mr-auto mt-2 mt-lg-0 ">
                        {navlinks?.map((item, key) => (
                            <li
                                key={key}
                                className={`nav-item  ${
                                    item?.url === route && "active"
                                } `}
                                onClick={() => navigateM(item?.url)}
                            >
                                {item?.name}
                            </li>
                        ))}
                        <li
                            className={`nav-item`}
                            onClick={() =>
                                navigateM(`/profile?user=${auth?.email}`)
                            }
                        >
                            Profile
                        </li>
                        <li
                            className={`nav-item`}
                            onClick={() => navigateM(`/chats`)}
                        >
                            Chats
                            {chatCount ? (
                                        <Badge
                                            severity="danger"
                                            className="smallNotificationBadge"
                                        ></Badge>
                                    ) : (
                                        ""
                                    )}
                        </li>
                        <li className={`nav-item`} onClick={() => signoutReq()}>
                            {token ? "Logout" : "Login"}
                        </li>
                    </ul>
                </div>
            </Sidebar>
        </div>
    );
}

export default Header;
