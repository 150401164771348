import React, { useEffect } from "react";
import Profile from "../Community/components/Profile";
import { user } from "../../DB";
import SuggestedFreinds from "../Community/components/SuggestedFreinds";
import FixturesSub from "../Community/components/Fixtures";
import TrendingNews from "../Community/components/TrendForYou/TrendingNews";
import TrendForYou from "../Community/components/TrendForYou/TrendForYou";
import { useDispatch, useSelector } from "react-redux";
import SingleBanterHeader from "./components/SingleBanterHeader";
import { viewBanterReq } from "../../utils/request";
import { getSingleBants } from "../../store/Banter";
import { useParams } from "react-router-dom";
import SingleBantsCard from "./components/SingleBantsCard";
/*eslint-disable*/

function SingleBanter() {
    const dispatch = useDispatch();
    const { id } = useParams();
    useEffect(() => {
        dispatch(getSingleBants(id)); //load the single data
        viewBanterReq(id); //view post
    }, []);
    const { singleBants } = useSelector((state) => state.banter);

    return (
        <div className=" community">
            <div className="container-fluid ">
                <div className="row m-0">
                    <div className="col-md-3 sidebarleft p-0 mx-auto centerPage d-none d-md-block">
                        <Profile user={user} />
                        <TrendingNews user={user} />
                        <SuggestedFreinds user={user} />
                    </div>
                    <div className="col-md-6 p-0 mx-auto centerPage">
                        <div className="mx-md-4">
                            <SingleBanterHeader />
                            <SingleBantsCard
                                item={singleBants?.bants}
                                isBants={true}
                            />
                        </div>
                    </div>
                    <div className="col-md-3 p-0 mx-auto centerPage d-none d-md-block">
                        <TrendForYou user={user} />
                        <FixturesSub user={user} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleBanter;
