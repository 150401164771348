import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addCommunityLikeReq } from "../../../../utils/request";
import { gePost } from "../../../../store/Community";
import ReplyForm from "./ReplyForm";
import { getSavePost } from "../../../../store/Community/index";

function ReplyCard({ item, user, parent_id }) {
    const dispatch = useDispatch();
    const [isload, setLoading] = useState(false);

    const likeFunc = async (id) => {
        setLoading(true);
        const res = await addCommunityLikeReq(id);
        if (res && res.data) {
            dispatch(gePost());
            dispatch(getSavePost());
            setLoading(false);
        } else {
            setLoading(false);
        }
    };
    return (
        <div className="CommentsCard">
            <div className="row-container row my-4">
                <div>
                    <img
                        src={
                            item?.user?.profile?.profile_photo ??
                            `/images/emeka.png`
                        }
                        alt=""
                        className="profile-avatar"
                    />
                </div>
                <div className="body-container  ml-3">
                    <h4 className="username">@{item.user?.username}</h4>
                    <p className="body">{item?.body}</p>
                    <div className="loveLike">
                        <span className="imgMiniBox ml-5">
                            <a
                                data-toggle="collapse"
                                href={`#collapseReply${item?.id}`}
                                aria-expanded="false"
                                aria-controls="collapseComment"
                            >
                                <img src="/images/box/reply.png" alt="" />
                            </a>
                            <span>
                                {item?.replies?.length > 0
                                    ? item?.replies?.length
                                    : ""}
                            </span>
                        </span>

                        <span className="imgMiniBox ">
                            {item?.likes?.find(
                                (isLike) => isLike?.user_id === user?.id
                            ) || isload ? (
                                <img
                                    onClick={() => likeFunc(item?.id)}
                                    src="/images/box/lovep.png"
                                    alt=""
                                />
                            ) : (
                                <img
                                    onClick={() => likeFunc(item?.id)}
                                    src="/images/box/love.png"
                                    alt=""
                                />
                            )}
                            {item?.likes?.length > 0 && (
                                <span> {item?.likes?.length}</span>
                            )}
                        </span>
                    </div>
                </div>
            </div>
            {/* set collpse to reply to comment */}
            <div
                className="collapse col-md-11 ml-auto mt-3 "
                id={`collapseReply${item?.id}`}
            >
                <ReplyForm
                    item={item}
                    user={user}
                    parent_id={parent_id}
                    body={item?.user?.username}
                />
            </div>
        </div>
    );
}

export default ReplyCard;
