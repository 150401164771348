import React from "react";
import {
    savePostReq,
    deletePostReq,
    pinPostReq,
} from "../../../../utils/request";
import { gePost, getSavePost } from "../../../../store/Community";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';

function DropDownList({ item, user }) {
    const dispatch = useDispatch();
    const savePost = async (data) => {
        await savePostReq({ post_id: data?.id });
        dispatch(gePost());
        dispatch(getSavePost());
    };
    const deletePost = async (data) => {
        await deletePostReq(data);
        dispatch(gePost());
        dispatch(getSavePost());
    };
    const pinPost = async (data) => {
      const res=  await pinPostReq(data, data?.id);
        dispatch(gePost());
        dispatch(getSavePost());
        if(res?.response?.status===403){
            toast.info(res?.response?.data?.message);
             }
    };

    return (
        <div className="dropdown-menu shadow-sm" aria-labelledby="triggerId">
            {item?.is_saved ? (
                <span
                    className="dropdown-item link"
                    onClick={() => savePost(item)}
                >
                    Unsave post
                </span>
            ) : (
                <span
                    className="dropdown-item link"
                    onClick={() => savePost(item)}
                >
                    Save Post
                </span>
            )}
            {/* <a className="dropdown-item" href="/community#">Edit Post</a> */}
            {user?.id === item?.user?.id && (
                <span
                    className="dropdown-item link"
                    onClick={() => deletePost(item)}
                >
                    Delete Post
                </span>
            )}
            {(user?.id === item?.user?.id  && item?.type !== "leaderboard")&&  (
                <span
                    className="dropdown-item link"
                    onClick={() => pinPost(item)}
                >
                    {!item.is_pinned ? "Pin Post" : "Unpin Post"}
                </span>
            )}
        </div>
    );
}

export default DropDownList;
