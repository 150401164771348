import React, { useEffect, useState } from "react";
import TrendingNews from "../TrendForYou/TrendingNews";
import SuggestedFreinds from "../SuggestedFreinds";
import Postcards from "../Post/Postcards";
import TrendForYou from "../TrendForYou/TrendForYou";
import FixturesSub from "../Fixtures";
import ViewProfile from "./ViewProfile";
import PostMediaBox from "./PostMediaBox";
import { useDispatch, useSelector } from "react-redux";
import { qParams } from "../../../../utils/getQueryParams";
import { getProfile } from "../../../../store/Auth";
// import { useSelector } from 'react-redux'
import { useLocation } from "react-router-dom";
import { getChat } from "../../../../store/Chat";
import Followings from "./Followings";
import Notification from "../../../Notification/index";

/*eslint-disable*/
function ShowProfile() {
    const dispatch = useDispatch();
    const location = useLocation();
    const [history, setHistory] = useState(window.location);
    const posts = useSelector((state) => state.community?.posts);
    const profile = useSelector((state) => state.auth?.profile);
    const auth = useSelector((state) => state.auth?.user);
    let type = qParams("type", location.search);
    const user = qParams("user", location.search);
    // const following = qParams("following", location.search);
    // const followers = qParams("followers", location.search);
    // const suggested = qParams("suggested", location.search);

    useEffect(() => {
        setHistory(window.location);
        dispatch(getProfile(user));
        dispatch(getChat(profile?.id));
        const intervalId = setInterval(() => {

            dispatch(getProfile(user)); 
    }, 5000); // Run every 5 seconds (5000 milliseconds)

    // Clean up function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
    }, [location]);
    const isfollow = true;
    const savedPost = useSelector((state) => state.community?.savePost);
    const userPosts = posts?.filter((item) => item.user_id === profile?.id);
    const mediaPost = posts?.filter(
        (item) => item.user_id === profile?.id && item.images.length > 0
    );

    const arrayList = [
        "following",
        "followers",
        "suggested",
        "my_post",
        "my_saved",
        "my_media",
        "suggested",
        "notification",
    ];
    return (
        <div className=" community">
            <div className="container-fluid ">
                <div className="row m-0">
                    <div className="col-md-3 sidebarleft p-0 mx-auto  centerPage d-none d-md-block">
                        <TrendingNews className="mt-0" />
                        <SuggestedFreinds length={3} auth={auth} />
                    </div>
                    <div className="col-md-6  p-0 mx-auto centerPage">
                        {profile && (
                            <div className="mx-md-4 ">
                                <ViewProfile
                                    profile={profile}
                                    auth={auth}
                                    history={history}
                                />
                                <PostMediaBox
                                    user={profile?.email}
                                    auth={auth}
                                />
                                {type === "suggested" && <SuggestedFreinds />}
                                {type === "my_saved" &&
                                    savedPost?.map((item, key) => (
                                        <Postcards
                                            item={item?.post}
                                            key={key}
                                        />
                                    ))}
                                {type === "my_media" &&
                                    mediaPost?.map((item, key) => (
                                        <Postcards item={item} key={key} />
                                    ))}
                                {type === "following" && (
                                    <Followings
                                        items={profile?.followings}
                                        title="Following"
                                    />
                                )}
                                {type === "followers" && (
                                    <Followings
                                        items={profile?.followers}
                                        title="Followers"
                                    />
                                )}

                                {(type == "my_post" || type == null) &&
                                    userPosts?.map((item, key) => (
                                        <Postcards item={item} key={key} />
                                    ))}
                            </div>
                        )}
                        {type === "notification" && <Notification />}
                        {!profile && !isfollow && (posts || savedPost) && (
                            <div className="mt-5 text-center">
                                <img
                                    src="/images/search_no_result.webp"
                                    className="w-100"
                                    alt=""
                                />
                            </div>
                        )}
                    </div>
                    <div className="col-md-3 p-0 mx-auto mt-3 centerPage d-none d-md-block">
                        <TrendForYou length={8} />
                        <FixturesSub />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShowProfile;
