import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DropDownListLoadBant from "./DropDownListLoadBant";

function BantCards({ item, isSingle }) {
    const user = useSelector((state) => state.auth?.user);
    const navigate = useNavigate();

    return (
        <div className="bantsCard">
            <div className="bantsTitle">
                <div className="bant-status"></div>
                <h5
                    onClick={() => !isSingle && navigate(`/banter/${item?.id}`)}
                >
                    {item?.topic}
                </h5>
                <p>{moment(item?.created_at).format("h:mm Do MMMM YYYY")}</p>
            </div>
            <div className="bantsDetails ">
                <div className="bant-first-col px-2">
                    <p>{item?.comments?.length} Replies</p>
                    <p>{item?.views_count} Views</p>
                </div>
                <div className="bant-secondcol-col px-2">
                    <p>Created By:</p>
                    <p className="user_name">{item?.user?.username}</p>
                </div>
                <div className="bant-third-col px-2">
                    <div className="dropdown">
                        <img
                            src="/images/box/vr.png"
                            className="link px-2 shadow-0 border-0"
                            alt=""
                            type="button"
                            id={`id-${item?.id}`}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        />
                        <DropDownListLoadBant item={item} user={user} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BantCards;
