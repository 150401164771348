import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch,  } from "react-redux";
import WebFont from 'webfontloader';
import { getFixturesToday, getLegues, getTeams } from "./store/LiveScores/Fixtures";
import { getCountry } from "./store/LiveScores/Matches";
import { setAuth } from "./store/General";
import { getUser } from "./store/Auth";
import { getFollowersFrends, getFollowingFrends, getKeywords, getSuggestedFrends } from "./store/Community";
import { getSavePost } from './store/Community/index';
import { getLastConvesation } from "./store/Chat";
import { getArticles } from "./store/Blog";
// import { , getRank } from "./store/LeaderBoard";
import { getNotificationCount } from "./store/Notification";

/*eslint-disable*/
function AppLoader() {

    const dispatch = useDispatch()

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        dispatch(getNotificationCount())

        dispatch(getLegues())
        // dispatch(getFixturesLive())
        dispatch(getFixturesToday())
        // dispatch(getFixturesDay1())
        dispatch(getCountry())
        dispatch(setAuth())
        // dispatch(getBlog())
        dispatch(getUser())
        dispatch(getTeams())
        dispatch(getArticles())
        dispatch(getSuggestedFrends())
        dispatch(getFollowersFrends())
        dispatch(getFollowingFrends())
        dispatch(getLastConvesation())
        dispatch(getSavePost())
        // dispatch(gePost())
        // dispatch(getLeaderBoardParticipateCheck())
        // dispatch(getLeaderBoard())
        // dispatch(getRank())
        // dispatch(getLastWeekTop())
        dispatch(getKeywords())

        WebFont.load({
            google: {
                families: ['Droid Sans', 'Chilanka']
            }
        });

        const intervalId = setInterval(() => {

                dispatch(getNotificationCount());
        }, 5000); // Run every 5 seconds (5000 milliseconds)

        // Clean up function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);





}

export default AppLoader
