import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
// import { useSelector } from "react-redux";
import { Tag } from "primereact/tag";

import CreateLeaderboard from "../../LeaderBoard/components/CreateLeaderboard";
function LoaderBoardInfoPage() {
    // const navigate = useNavigate();
    const checkPaticipant = useSelector(
        (state) => state.leaderboard.paticipateCheck
    );
    const auth = useSelector((state) => state.auth.user);
    const [visible, setVisible] = useState(false);

    const { current_leaderBoard } = useSelector((state) => state.leaderboard);

    return (
        <div className="LoaderBoardInfoPage">
            <div
                class="alert alert-success shadow-sm alert-dismissible fade show text-center"
                role="alert"
            >
                {/* <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                    <span class="sr-only">Close</span>
                </button> */}

                <div className="marquee-container">
                    <div className="marquee">
                        <b>
                            Read the instructions carefully before joining a
                            challenge.
                        </b>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row m-0 my-5">
                    <div>
                        <h1 className="mb-0">Leaderboard</h1>
                        <span>Join the biggest reward entries</span>
                    </div>

                    <div className="text-right col-8 ml-auto">
                        <Tag
                            style={{
                                background:
                                    "linear-gradient(-225deg,#DBD8DC 0%,#B2A7BF 48%,#959399 100%)",
                                color: "white",
                                fontSize: "14px",
                            }}
                            value={current_leaderBoard?.type}
                            className="text-white shadow tagLeaderBoardType"
                        ></Tag>
                    </div>
                </div>
                <div className="mb-4">
                    <h3 className="font-weight-bold text-uppercase">
                        How Leaderboard works
                    </h3>

                    <p>
                        Leaderboard serves as a scouting pool for young
                        footballers to showcase their talents, and a challenge
                        hub for anyone interested in starting a career in the
                        football industry to get access to life changing
                        opportunities. The challenge will occur in two
                        categories which are the weekly category, and the
                        monthly category.
                    </p>
                    <p>
                        <h5>Mode Of Operation</h5>
                        There will be a weekly challenge and a monthly
                        challenge. Each of them will have 2 stages (Phase One,
                        and Phase Two respectively).
                    </p>
                    <p>
                        <h5>Weekly</h5>


The weekly challenge will last for a duration of seven (7) days unless terminated before completion. It will begin on any day of the week and end 7 days after the commencement date. The first 4 days are classified as phase one, while the last 3 days are classified as phase two. Entries are to be submitted during phase one through the leaderboard portal. This content will be automatically pinned to the top of your page. At phase one, you will be required to get engagements from the community to the content you've submitted. The top 10 content on the community with the highest engagement (likes) will be automatically selected to move to the next stage of the challenge (Phase Two). <br/>
At phase two, voting will commence through the voting portal to choose the top 3 from the 10 participants that made it through to the final stage. Every community member will have access to the voting portal from day 5 and will be allowed to cast one vote for their favourite content or participant. Phase one starts on the date the challenge begins and ends by 10:00pm on the 4th day while phase two begins by 00:01am on the 5th day where voting will commence immediately on the voting portal. Phase two ends at 10:00pm on the 7th day while the top 3 winners will be contacted thereafter.
                    </p>
                    <p>
                        <h5>Monthly</h5>
                        The monthly challenge will last for a duration of thirty
                        (30) days unless terminated before completion. It will
                        begin on any day of the week and end 30 days after the
                        commencement date. The first 15 days is classified as
                        phase one, while the last 15 days is classified as phase
                        two. The same entry and voting process above is
                        applicable to the monthly challenge. Every community
                        member will have access to the voting portal from the
                        16th day of the challenge and will be allowed to cast
                        one vote for their favourite content or participant out
                        of the 10 participants. Phase one starts on the date the
                        challenge begins and ends by 10:00pm on the 15th day
                        while phase two begins by 00:01am on the 16th day where
                        voting will commence immediately on the voting portal.
                        Phase two ends by 10:00pm on the 30th day while the top
                        3 winners will be contacted thereafter.
                    </p>
                </div>
                <div>
                    <p className="mt-5">
                        <h3 className="font-weight-bold text-uppercase">
                            {" "}
                            Current Task
                        </h3>
                        {!current_leaderBoard?.public_description
                            ? `There is no Challenge going on at the moment, please check back later.`
                            : current_leaderBoard?.public_description ??
                              current_leaderBoard?.description}
                    </p>
                </div>
                <Dialog
                    header=""
                    visible={visible}
                    style={{ width: "70vw", borderRadius: 12 }}
                    closable={true}
                    onHide={() => setVisible(false)}
                    headerClassName="p-0"
                    focusOnShow={false}
                >
                    <div className="modal-body cratePostTab p-0  ">
                        {/* pull in tab */}
                        <ul
                            className="nav nav-pills   mb-3 border-bottom "
                            id="pills-tab"
                            role="tablist"
                        >
                            <li className="nav-item">
                                <a
                                    className="nav-link active link"
                                    id="pills-profile-tab-2"
                                    data-toggle="pill"
                                    href="#pills-profile"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected="false"
                                >
                                    Create Leaderboard
                                    <span className="badge badge-primary bg-muted">
                                        {checkPaticipant?.alreadyParticipated
                                            ? 0
                                            : 1}
                                    </span>
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            {checkPaticipant?.leaderboard?.status !== true && (
                                <div
                                    className="tab-pane fade show active"
                                    id="pills-profile"
                                    role="tabpanel"
                                    aria-labelledby="pills-profile-tab-2"
                                >
                                    <CreateLeaderboard
                                        auth={auth}
                                        changeVisibility={(e) => setVisible(e)}
                                        checkPaticipant={checkPaticipant}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </Dialog>
                <div className="text-center mt-4">
                    {checkPaticipant?.alreadyParticipated}
                    <button
                        className="btn join_btn"
                        data-toggle="modal"
                        data-target="#loadPopup"
                        onClick={() => setVisible(true)}
                        disabled={
                            !current_leaderBoard?.public_description
                                ? true
                                : false
                        }
                    >
                       {"Join Leaderboard"}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LoaderBoardInfoPage;
