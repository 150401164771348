import React, { useState,useRef } from "react";
import { useDispatch } from "react-redux";
import { addCommunityLikeReq } from "../../../../utils/request";
import { gePost } from "../../../../store/Community";
import ReplyCard from "../Replies/ReplyCard";
import ReplyForm from "../Replies/ReplyForm";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
// import { Toast } from 'primereact/toast';

function CommentsCard({ item, user }) {
    const dispatch = useDispatch();
    const [isload, setLoading] = useState(false);
    const toast = useRef(null);

    const accept = () => {
        toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    };

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const likeFunc = async (id) => {
        setLoading(true);
        const res = await addCommunityLikeReq(id);
        if (res && res.data) {
            dispatch(gePost());
            setLoading(false);
        } else {
            setLoading(false);
        }
    };
    const showTemplate = () => {
        confirmDialog({
            group: 'templating',
            header: 'Confirmation',
            message: (
                <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
                    <i className="pi pi-exclamation-circle text-6xl text-primary-500"></i>
                    <span>Please confirm to proceed moving forward.</span>
                </div>
            ),
            accept,
            reject
        });
    };




    return (
        <div className="CommentsCard">
            <div className="row-container row mb-4 pb-3">
                <div>
                    <img
                        src={
                            item?.user?.profile?.profile_photo ??
                            `/images/emeka.png`
                        }
                        alt=""
                        className="profile-avatar"
                    />
                </div>
                <div className="body-container  ml-3">
                    <h4 className="username">@{item.user?.username}</h4>
                    <p className="body">{item?.body}</p>
                    <div className="loveLike">
                        <span className="imgMiniBox ml-3">
                            <span className={`link`} >
                                <i className="fa fa-trash-o" alt="" ></i>
                            </span>
                        </span>
                        <span className="imgMiniBox ml-5">
                            <a
                                data-toggle="collapse"
                                href={`#collapseComment${item?.id}`}
                                aria-expanded="false"
                                aria-controls="collapseComment"
                            >
                                <img src="/images/box/reply.png" alt="" />
                            </a>
                            <span>
                                {item?.replies?.length > 0
                                    ? item?.replies?.length
                                    : ""}
                            </span>
                        </span>

                        <span className="imgMiniBox ">
                            {item?.likes?.find(
                                (isLike) => isLike?.user_id === user?.id
                            ) || isload ? (
                                <img
                                    onClick={() => likeFunc(item?.id)}
                                    src="/images/box/lovep.png"
                                    alt=""
                                />
                            ) : (
                                <img
                                    onClick={() => likeFunc(item?.id)}
                                    src="/images/box/love.png"
                                    alt=""
                                />
                            )}
                            {item?.likes?.length > 0 && (
                                <span> {item?.likes?.length}</span>
                            )}
                        </span>
                    </div>
                </div>
            </div>
            {/* set collpse to reply to comment */}
            <ConfirmDialog  />
            {/* <Toast ref={toast} /> */}

            <div
                className="collapse col-md-11 ml-auto mt-3"
                id={`collapseComment${item?.id}`}
            >
                <ReplyForm
                    item={item}
                    user={user}
                    parent_id={item?.id}
                    body={item?.user?.username}
                />
            </div>
            {/* all reples here */}
            <div className="replies pl-4">
                {item?.replies?.map((reply, key) => (
                    <ReplyCard
                     
                        item={reply}
                        key={key}
                        user={user}
                        comment_id={item?.id}
                    />
                ))}
            </div>
        </div>
    );
}

export default CommentsCard;
