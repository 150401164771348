import moment from 'moment';
import React from 'react'
import { useNavigate } from 'react-router-dom';


const RankVideo = ({ data }) => {
    const navigate = useNavigate()
    return (
        <div className='videos shadow-sm ' onClick={() => navigate(`${data?.id ?? data}`)}>
            <video width="100%" height="100%" autoplay>
                <source src={data?.video} type="video/mp4" />
            </video>
            <div className="detailed">
                <img src={data?.user?.profile?.profile_photo} alt="" className='shadow' />
                <div className='info' >
                    <h6>{data?.user?.username}</h6>
                    <small>{moment(data?.created_at).fromNow()}</small>
                </div>

            </div>
        </div>
    )
}


function RankStories({ leaderboard_challange }) {

    return (
        <div className="Videosdivider">


            {leaderboard_challange && leaderboard_challange?.map((data) => (
                <RankVideo data={data} />
            ))}
        </div>
    );
}

export default RankStories
