import React from "react";
import moment from "moment";

function NotificationCard({ item }) {
    return (
        <div className="notification-card bg-white mt-2">
            <div className="notification-image">
                <img
                    src={item?.creator?.profile?.profile_photo}
                    alt="Notification"
                    className="border"
                />
            </div>
            <div className="notification-content">
                <div className="notification-title">{item?.title}</div>

                <div className="notification-description">
                    {item?.description}
                </div>
                <div className="notification-time text-right">
                    {moment(item?.created_at).fromNow()}
                </div>
            </div>
        </div>
    );
}

export default NotificationCard;
