import React from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { searchBlogFun } from '../../../../store/Blog';

function InputMobile() {
    const location = useLocation()
    const isCurrent = location.pathname;
    const dispatch = useDispatch()

    const loadSearch = (e) => dispatch(searchBlogFun(e.target.value))
    if (isCurrent !== '/contact' &&
        isCurrent !== '/terms' &&
        isCurrent !== '/policy'
    )
        return (
            <section id="heroInputMobile" className='d-md-none d-block'>
                <img src="/images/search.png" alt="" />
                <input type="text" placeholder='Search Topics and Tags' onChange={(e) => loadSearch(e)} />
            </section>
        )
}

export default InputMobile
