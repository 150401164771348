import moment from "moment";
import React, {  useState } from "react";
import { addCommunityLikeReq, savePostReq } from "../../../../utils/request";
import { gePost } from "../../../../store/Community";
import { useDispatch, useSelector } from "react-redux";
import CommentContainer from "../Comments/CommentContainer";
import DropDownList from "./DropDownList";
import { useNavigate } from "react-router-dom";
import { getSavePost } from "../../../../store/Community/index";
import ImagePostCards from "./ImagePostCards";
import VideoPlayer from "./VideoPlayer";
// import { Tooltip } from 'primereact/tooltip';
import BodyPost from "./BodyPost";
// import { toast } from 'react-toastify';

function Postcards({ item }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth?.user);
    const [isload, setLoading] = useState(false);
    const savePost = async (data) => {
        await savePostReq({ post_id: data?.id });
        dispatch(gePost());
        dispatch(getSavePost());
    };
    const likeFunc = async (id) => {
        setLoading(true);
        const res = await addCommunityLikeReq(id);
        // console.debug(res)
        if (res && res.data) {
            dispatch(gePost());
            dispatch(getSavePost());
            setLoading(false);
        }

        else {
            setLoading(false);
        }
    };

    const [key, setKey] = useState(Date.now()); // Initialize key with current timestamp

    const handleReload = () => {
        setKey(Date.now()); // Update key with new timestamp to force re-render
    };


    return (
        <div
            className={`post-cards shadow-sm border ${
                item?.type === "leaderboard" && "leaderBoardDesign"
            }`}
            id={`post${item?.id}`}
        >
            <div className="row m-0">
                <div className="col-12 p-0">
                    <p className="topinfo pr-0">
                        <div className="users">
                            <img
                                src={
                                    item?.user?.profile?.profile_photo ??
                                    `/images/emeka.png`
                                }
                                className={"link profile-avatar"}
                                onClick={() =>
                                    navigate(
                                        `/profile?user=${item?.user?.email}`
                                    )
                                }
                                alt=""
                            />
                            <span
                                onClick={() =>
                                    navigate(
                                        `/profile?user=${item?.user?.email}`
                                    )
                                }
                                className="name link ml-2"
                            >
                                {item?.user?.profile?.first_name
                                    ? item?.user?.profile?.first_name +
                                      " " +
                                      item.user?.profile?.last_name
                                    : item?.user?.username}
                            </span>
                            <span className="time">
                                {moment(item?.created_at).fromNow()}
                            </span>
                            {/* {item?.type !== 'post' && <img src="/images/mdi_trophy0.png" className='leaderboardTrophy' alt="" />} */}
                        </div>

                        <div className="imgBox ">
                            <div className="dropdown">
                                <span className="tag-leaderboard">

                                    {item?.is_pinned &&
                                     user?.id === item?.user_id ? (
                                        <img
                                            src="/images/bi_pin-fill.png"
                                            alt=""
                                        />
                                    ) : (
                                        ""
                                    )}

                                    {item?.type === "leaderboard" && (
                                        <span
                                            tooltip="LeaderBoard Post"
                                            className="badge badge-blue d-none d-md-inline link"
                                        >
                                            Leaderboard
                                        </span>
                                    )}
                                    {item?.type === "leaderboard" && (
                                        <span
                                            tooltip="LeaderBoard Post"
                                            className="badge badge-blue link d-inline d-md-none"
                                        >
                                            <i className="pi pi-chart-bar"></i>
                                        </span>
                                    )}
                                </span>

                                <img
                                    src="/images/box/vr.png"
                                    className="link btn shadow-0 border-0"
                                    alt=""
                                    type="button"
                                    id={`id-${item?.id}`}
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                />
                                <DropDownList item={item} user={user} />
                            </div>
                        </div>
                    </p>
                    <p className="post_text">

                    <BodyPost body={item?.body}/>


                        {/* {item?.description} */}
                        {/* {item?.leaderboard_challenges?.leaderboard
                            ?.hash_tag && (
                            <small className="d-block mt-2 font-weight-bolder text-italic">
                                #
                                {
                                    item?.leaderboard_challenges?.leaderboard
                                        ?.hash_tag
                                }{" "}
                            </small>
                        )} */}
                    </p>

                    <div className="text-center">
                        {item?.images.length ?  <ImagePostCards image={item?.images} height={"400px"} />: ""}
                    </div>


                    {item?.type === "leaderboard" &&
                        item?.leaderboard_challenges?.video && (
                            <VideoPlayer
                                video={item?.leaderboard_challenges?.video}
                            />
                        )}

                    <div className="footer-box">
                        <span className="tag">
                            <a
                                data-toggle="collapse"
                                onClick={handleReload}
                                href={`#collapse${item?.id}`}
                                aria-expanded="false"
                                aria-controls="contentId"
                            >
                                <img
                                    src="/images/box/comment.png"
                                    title="Comments"
                                    alt=""
                                />
                            </a>
                            {item?.comments?.length > 0 && (
                                <span> {item?.comments?.length}</span>
                            )}
                        </span>
                        <span className="tag">
                            {item?.likes?.find(
                                (isLike) => isLike?.user_id === user?.id
                            ) || isload ? (
                                <img
                                    onClick={() => likeFunc(item?.id)}
                                    src="/images/box/lovep.png"
                                    title="Unlike Post"
                                    alt=""
                                />
                            ) : (
                                <img
                                    onClick={() => likeFunc(item?.id)}
                                    src="/images/box/love.png"
                                    title="Like Post"
                                    alt=""
                                />
                            )}
                            {item?.likes?.length > 0 && (
                                <span> {item?.likes?.length}</span>
                            )}
                        </span>
                        <span className="tag">
                            {!item?.is_saved && (
                                <img
                                    src="/images/box/connect.png"
                                    alt=""
                                    title="Save Post"
                                    onClick={() => savePost(item)}
                                />
                            )}
                        </span>
                    </div>
                    {/* collpase comment */}
                    <div className="collapse" id={`collapse${item?.id}`} key={item?.id}>
                        <CommentContainer item={item}  user={user} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Postcards;
