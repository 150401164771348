import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { followReq } from "../../../../utils/request";
import { toast } from "react-toastify";
import { getSuggestedFrends } from "../../../../store/Community";
import ListLoader from "../../../../Loaders/ListLoader";
import { Link } from "react-router-dom";

function SuggestedFreinds({ length ,auth}) {
    const users = useSelector((state) => state.community.users?.users);
    const dispatch = useDispatch();
    const followFunc = async (following_id) => {
        const res = await followReq({ following_id });
        if (res && res.data) {
            toast.success(res?.data?.message);
            dispatch(getSuggestedFrends());
        } else if (res && res?.response) {
            toast.error(res?.response?.data?.message);
        } else {
            toast.info("Oops something went wrong");
        }
    };
    if (!users) return <ListLoader />;

    if (users?.length)
        return (
            <div className="trending_news suggestedfreinds headbox border">
                <div className="header">
                    <span> Suggested Friends</span>
                </div>
                <div className="postNews">
                    {users?.slice(0, length)?.map((item, key) => (
                        <div className="row m-0 p-2  " key={key}>
                            <div className="col-4 p-0 ">
                                <img
                                    src={item?.profile?.profile_photo}
                                    className="profile-img shadow"
                                    alt=""
                                />
                            </div>
                            <div className="col-8 p-0">
                                <div className="row m-0 p-0">
                                    <div className="col-7 p-0">
                                        <h3>
                                            {item?.profile?.first_name?.slice(
                                                0,
                                                10
                                            ) ?? item?.username.slice(0, 10)}
                                            ...
                                        </h3>
                                        <p>@{item?.username.slice(0, 10)}</p>
                                    </div>
                                    <div className="col-5 p-0">
                                        <button
                                            className="btn isfollow"
                                            onClick={() => followFunc(item?.id)}
                                        >
                                            Follow
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 py-1 m-0">
                                <hr className="p-0 m-0" />
                            </div>
                        </div>
                    ))}
                    {length ? (
                        <div className="text-center  p-2">
                            <Link
                                to={`/profile?type=suggested&user=${auth.username}`}
                                className="link-btn btn"
                            >
                                See more
                            </Link>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
}

export default SuggestedFreinds;
