import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Draggable, { DraggableCore } from 'react-draggable'; // Both at the same time
// import { useSelector } from 'react-redux'

// import { AutoComplete } from "primereact/autocomplete";

function Search() {
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    // const users = useSelector((state) => state.community?.users);

    // const [items, setItems] = useState([]);
    const suffixes = [
        { name: "Banter", url: "/banter", keyword: "keyword" },
        { name: "Blog", url: "/blog", keyword: "keyword" },
        { name: "Post", url: "/community", keyword: "keyword" },
        { name: "User", url: "/profile", keyword: "user" },
        { name: "", url: "/profile", keyword: "user" },
    ];

    const handleLoad = (e) => {
        e.preventDefault();
        navigate(`/profile?user=${search}`);
    };
    return (
        <form
            className={`form-inline my-2 my-lg-0  `}
            onSubmit={(e) => handleLoad(e)}
        >
            <img src="/images/nav/search.png" className="search" alt="" />
            <input
                className="form-control mr-sm-2 "
                type="search"
                placeholder="Search..."
                onChange={(e) => setSearch(e.target.value)}
            />

            {search && (
                <div className="dropdown-search">
                    {suffixes.map((item, key) => (
                        <div className="list-group">
                            {key !== 4 ? (
                                <Link
                                    key={key}
                                    to={`${item.url}?${item.keyword}=${search}`}
                                    className="list-group-item list-group-item-action link"
                                >
                                    <b>{item.name}</b> -{search?.slice(0, 20)}
                                    {search?.length >= 20 && "..."}
                                </Link>
                            ) : (
                                <Link
                                    key={key}
                                    to={`/search?keyword=${search}`}
                                    className="list-group-item list-group-item-action link text-center"
                                >
                                    <b>Load More</b>
                                </Link>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </form>
    );
}

export default Search;
