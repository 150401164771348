import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { followersReq, followingReq, getPostReq, getSavePostReq, getSuggestedFrendsReq, keywordsReq } from "../../utils/request";

export const gePost = createAsyncThunk(
    'user/gePost',
    async (payload) => {
        const res = await getPostReq(payload);
        return res.data;
    }
)
export const getSuggestedFrends = createAsyncThunk(
    'user/getSuggestedFrends',
    async () => {
        const res = await getSuggestedFrendsReq();
        return res.data;
    }
)
export const getFollowersFrends = createAsyncThunk(
    'user/getFollowersFrends',
    async () => {
        const res = await followersReq();
        return res.data?.myFollowers;
    }
)
export const getFollowingFrends = createAsyncThunk(
    'user/getFollowingFrends',
    async () => {
        const res = await followingReq();
        return res.data?.myFollowing;
    }
)
export const getSavePost = createAsyncThunk(
    'user/getSavePost',
    async () => {
        const res = await getSavePostReq();
        return res.data?.savedPosts;
    }
)
export const getKeywords = createAsyncThunk(
    'user/getKeywords',
    async () => {
        const res = await keywordsReq();
        return res.data;
    }
)
export const community = createSlice(
    {
        name: "community",
        initialState: {
        },
        posts: "",
        users: "",
        loader: false,
        followings: "",
        savePost: "",
        followers: "",
        keywords: "",
        reducers: {
            openIschat: (state, action) => {
                //
            },

        },
        extraReducers: (builder) => {
            builder.addCase(gePost.fulfilled, (state, action) => {
                state.loader = true
                state.posts = action.payload
            })
            builder.addCase(getSuggestedFrends.fulfilled, (state, action) => {
                state.users = action.payload
            })
            builder.addCase(getFollowingFrends.fulfilled, (state, action) => {
                state.followings = action.payload
            })
            builder.addCase(getFollowersFrends.fulfilled, (state, action) => {
                state.followers = action.payload
            })
            builder.addCase(getSavePost.fulfilled, (state, action) => {
                state.savePost = action.payload
            })
            builder.addCase(getKeywords.fulfilled, (state, action) => {
                state.keywords = action.payload
            })

        }

    });


export const { openIschat } = community.actions;

export default community.reducer;
