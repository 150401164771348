import React from 'react'

function SideTextLeaderBoard({ current_leaderBoard }) {
    return (
        <div>
            <h1>Contest Leaderboard.</h1>

            <p>
                <h6>Instructions:</h6>
                {current_leaderBoard?.public_description ??
                    current_leaderBoard?.description}
            </p>
        </div>
    );
}

export default SideTextLeaderBoard
