
import CommentsCard from './CommentsCard';
import CommentForm from './CommentForm';

function CommentContainer({ item, user }) {

    return (
        <div className='CommentContainer px-4'>
            <hr />
            <CommentForm item={item } key={item} user={user} />
            {/* load all the comments */}
            <div className="mt-4 p-3 ">
                {
                    item?.comments?.map((comments, key) => <CommentsCard item={comments} key={key} user={user} />)
                }
            </div>

        </div>
    )
}

export default CommentContainer
