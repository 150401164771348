import React  from "react";

import { SpeedDial } from "primereact/speeddial";

export default function SelectChat(props) {
    // const [selected, setSelected] = useState("users-chats");
    const sendDataToParent = (dataToSend) => {
        // Send data to the parent using the callback function
        props.sendDataToParent(dataToSend || "users-chats");
    };

    // const items = [
    //     {
    //         label: "Currents Chats Users",
    //         icon: "pi pi-comments   ",
    //         command: () => {
    //             sendDataToParent("users-chats");
    //             // setSelected("users-chats");
    //         },
    //     },
    //     {
    //         label: "Followers",
    //         icon: "pi pi-users",

    //         command: () => {
    //             sendDataToParent("followers-chats");
    //             // setSelected("followers-chats");
    //         },
    //     },
    // ];

    return (
        <div className="p-0">
            {/* <SpeedDial model={items} direction="up" /> */}
          <div class="toolTipsConversation row p-0 m-0">
             <small className="current-chats link shadow-sm" onClick={()=>sendDataToParent("users-chats")}>Conversation</small>
           <small className="current-followers link shadow-sm" onClick={()=> sendDataToParent("followers-chats")}>Followers</small>
          </div>
        </div>
    );
}
