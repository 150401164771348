import React, { useState } from "react";
// import WebSocketClient from "websocket";
import {  toast } from "react-toastify";
import { createPostReq } from "../../../../utils/request";
import { gePost } from "../../../../store/Community";
import { useDispatch } from "react-redux";
import { Badge } from "primereact/badge";
// import { Editor } from "@tinymce/tinymce-react";
import { Editor } from "primereact/editor";

// import { Checkbox } from "primereact/checkbox";

function CreatePost({ auth, changeVisibility }) {
    const dispatch = useDispatch();
    const [value, setformValue] = useState("");
    const [isload, setLoading] = useState(false);
    const comment_input = document.querySelector("#postMessage");
    // const [img, setImg] = useState([]);
    const [photos, setPhotos] = useState([]);

    // const addValue = (e) => {
    //     setformValue({ ...value, [e.target.name]: e.target.value });
    // };

    const formData = new FormData();
    const handleSubmit = async (e, is_draft) => {
        toast.success("Uploading Post...");

        e.preventDefault();
        setLoading(true);
        changeVisibility(false);
        //close modal toggle

        for (let i = 0; i < photos.length; i++) {
            formData.append(`photos[${i}]`, photos[i]);
        }
        formData.append("body", value ?? " ");
        formData.append("is_draft", is_draft);
        const res = await createPostReq(formData);

        dispatch(gePost());
        if (res && res.data) {
            comment_input.value = "";
            setLoading(false);
            dispatch(gePost());
           toast.success("Post Uploaded successfully!");

            // ModalClose("loadPopup")
            // window.location.reload()
            // toast.success("Post Created Successfully!");
        } else if (res && res?.response) {
            toast.error(res?.response?.data?.message);

            setLoading(false);
        } else {
            // toast.update(id, {
            //     render: "Post unable to create!",
            //     type: "error",
            //     isLoading: "false",
            // });
            toast.info("Oops something went wrong");
            setLoading(false);
        }
    };
    const [previewUrls, setPreviewUrls] = useState([]);

    const addImage = (e) => {
        let input = e.target.files;
        setPhotos(input);
        const selected = Array.from(input);
        const newPreviewUrls = selected.map((file) =>
            URL.createObjectURL(file)
        );
        setPreviewUrls(newPreviewUrls);
    };

    const removeImage = (indexToRemove) => {
        setPreviewUrls((prevUrls) =>
            prevUrls?.filter((url, index) => index !== indexToRemove)
        );

        setPhotos((photos) =>
            Array.from(photos)?.filter((url, index) => index !== indexToRemove)
        );
    };

    return (
        <form className="postBox" onSubmit={(e) => handleSubmit(e, 0)}>
            <div className="row m-0">
                <div className="col-2 text-center p-0 d-none d-md-block">
                    <img
                        src={auth?.profile?.profile_photo}
                        alt=""
                        className="profile-large-avatar"
                    />
                </div>
                <div className="col-md-9 p-md-0">
                    <div className="editorBox">
                        <Editor
                            focus={true}
                            value={value}
                            onTextChange={(e) => setformValue(e.htmlValue)}
                            className="text_Editor shadow-sm"
                        />
                    </div>

                    <div className="previewPost">
                        {previewUrls?.length <= 5 &&
                            previewUrls.map((url, index) => (
                                <div className="mt-3">
                                    <Badge
                                        value="x"
                                        severity="danger"
                                        className="removePix"
                                        onClick={() => removeImage(index)}
                                    ></Badge>
                                    <img
                                        key={index}
                                        src={url}
                                        alt="Preview"
                                        className="shadow "
                                    />
                                </div>
                            ))}
                    </div>
                    {photos?.length > 5 ? (
                        <small className="text-danger ">
                            Image should not exceeds 5
                        </small>
                    ) : (
                        ""
                    )}
                    <div className="postBottom">
                        <div className="f-img">
                            <div className="file-input">
                                <input
                                    type="file"
                                    name="file-input"
                                    id="file-input"
                                    className="file-input__input"
                                    multiple
                                    accept="image/png,image/jpg,image/png,image/jpeg"
                                    maxLength={5}
                                    onChange={(e) => addImage(e)}
                                />
                                <label
                                    className="file-input__label"
                                    for="file-input"
                                >
                                    <span>
                                        Add Photos to your post
                                        <span className="ml-3">
                                            <img
                                                src="/images/uploadImage.png"
                                                alt=""
                                            />
                                            {/* <img
                                                src="/images/uploadVideo.png"
                                                alt=""
                                            /> */}
                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {value ? (
                            <div className="s-img">
                                <button
                                    className="btn add-post  d-none"
                                    disabled={
                                        isload === true || photos?.length > 5
                                            ? true
                                            : false
                                    }
                                    type="button"
                                    onClick={(e) => {
                                        handleSubmit(e, 1);
                                    }}
                                >
                                    {isload ? "Saving..." : "Save as draft"}
                                </button>
                                <button
                                    className="btn add-post "
                                    disabled={
                                        isload === true || photos?.length > 5
                                            ? true
                                            : false
                                    }
                                >
                                    {isload ? "Posting..." : "Post"}
                                </button>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>

            {/* <ToastContainer   /> */}
        </form>
    );
}

export default CreatePost;
